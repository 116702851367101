import NavBarView from 'presentation/view/navigation/NavigationBarView'
import React from 'react'

export default function HelpAccountDeleteView() {
	return (
		<div className='flex min-h-screen w-full flex-col'>
			<NavBarView />
			<div className='container px-5 mx-auto xl:px-5 max-w-screen-lg !pt-0'>
				<div className='relative sm:rounded-lg overflow-hidden p-5 px-5 container mx-auto max-w-screen-lg mb-32'>
					<div className='w-full max-w-screen-xl'>
						<div className='relative overflow-hidden border sm:rounded-lg p-5'>
							<code className='block'>
								<p>Инструкция по удалению аккаунта в Entrum</p>
								<br />
								Мы сожалеем о вашем решении удалить аккаунт.
								<br />
								<br />
								Для удаления аккаунта выполните следующие шаги:
								<br />
								1. Зайдите в приложение и авторизируйтесь под своим аккаунтом
								<br />
								2. Перейдите в настройки
								<br />
								3. Нажмите кнопку Удалить аккаунт
								<br />
								<br />
								После удаления аккаунта, безвозвратно будут утеряны все персональные данные и любая информация, которую
								вы когда бы то ни было указывали в Entrum.
							</code>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
